import React from "react";
import { compose } from "recompose";
import Layout from "../utils/layout";
import { withAuthorization, withEmailVerification } from "../utils/Session";
import Team from "../components/scenes/Team";

const condition = authUser => !!authUser;
// const TeamPage = ({ teamData }) => {
//   const TeamComponent = <Team teamData={teamData} />;
//   return compose(
//     withEmailVerification,
//     withAuthorization(condition)
//   )(TeamComponent)};

const TeamPageComponent = ({ location }) => {
  const { state = {} } = location;
  const { teamData } = state;
  return (
    <Layout>
      <Team teamData={teamData} />
    </Layout>
  );
};

export default TeamPageComponent;