import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../utils/Firebase";
import Divider from "../../atoms/Divider";
import CountryISOCodes from "../../../data/countryISOcodes.json";
import getCountryFlag from "../../../scripts/getCountryFlag";
import { get } from "core-js/library/fn/dict";
// import ResourceTable from "./atoms/ResourceTable";

const Team = ({ firebase, teamData }) => {
  const { currentUser } = firebase ? firebase.auth : { currentUser: null };
  const [users, setUsers] = useState([]);
  const [currentTeamData, setCurrentTeamData] = useState(teamData);
  const [refresh, setRefresh] = useState(0);
  const getUsers = () => {
    firebase
    .users()
    .where("teams", "array-contains", teamData.teamId)
    .get()
    .then((querySnapshot) => {
      let array = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        array.push(doc.data());
      });
      setUsers(array);
    })
    .catch((err) => console.log(err));
  };

  const removeUserFromTeam = async (userUid, teamId) => {
    try {
      await firebase.removeTeamFromUser(userUid, teamId);
    }
    catch (err) {
      console.log(err)
    }
    finally {
      getUsers();
    }
  }

  useEffect(() => {
    if (currentUser || !currentTeamData) {
      console.log("useEffect: getMinistries")
      getUsers()
    }
  }, [currentUser, firebase, refresh])

  if (!teamData) return null;

  const makeLeader = async (teamId, userId) => {
    await firebase.makeUserTeamLeader(userId);
    await firebase.addTeamLeader(teamId, userId)
    await firebase.team(teamId).get().then((snapshot) => {
      const updatedTeamData = snapshot.data();
      setCurrentTeamData(updatedTeamData);
    });
    setRefresh(refresh+1);

  }

  return (
    <div className="home container">
      <div className="ministry__details">
        <h1 className="ministry__title">
          {getCountryFlag(CountryISOCodes[currentTeamData.country])} Team:{" "}
          {currentTeamData.name}
        </h1>
      </div>
      <h3>Contact Email: {teamData.email}</h3>
      <div className="ministry__divider">
        <Divider />
      </div>
      <div className="ministry">
        <div>
          <table className="resources__table">
            <tbody>
              <tr className="resources__table__row">
                <th className="resources__table__cell">Email</th>
                <th className="resources__table__cell">Role</th>
              </tr>
              {users.map((user) => (
                <tr className="resources__table__row">
                  <td>{user.email}</td>
                  <td>
                    {teamData.leaders.find((uid) => uid === user.uid) ? (
                      "Team Leader"
                    ) : (
                      <div
                        className="resources__removeBtn__blue"
                        onClick={() => {
                          makeLeader(currentTeamData.teamId, user.uid);
                        }}
                      >
                        + MAKE A LEADER OF THIS TEAM
                      </div>
                    )}
                    <div
                      className="resources__removeBtn__red"
                      onClick={() => {
                        removeUserFromTeam(user.uid, currentTeamData.teamId);
                      }}
                    >
                      - REMOVE
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(Team);
